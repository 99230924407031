import dayjs from 'dayjs';
import { computed } from 'vue';
import { DATE_FORMAT } from '@/utils/metadata';

const toDayjs = (input, startOrEnd) => {
  let value
  if (startOrEnd !== undefined && typeof input !== "string" && !(input instanceof Date)) {
    value = startOrEnd === "start" ? input.start : input.end
  }
  if (typeof input === "string") {
    value = input
  } else if (input instanceof Date) {
    return dayjs(input)
  }
  const format = DATE_FORMAT;
  return dayjs(value, format, true)
}

const format = (input, pattern) => {
  if (pattern === false) {
    return input instanceof Date ? input : dayjs(input).toDate()
  }
  const inputDayjs = typeof input === "string" || input instanceof Date ? toDayjs(input) : input

  return inputDayjs.format(pattern)
}

// function toDayjs(str) {
//   return dayjs(str, DATE_FORMAT);
// }

export default function useTimePositionMapping(timeline) {
  const chartSizeWidth = computed(() => {
    const watchTrigger = timeline.value
    return document.querySelector('.g-gantt-chart')?.clientWidth
  })

  const chartStartDayjs = computed(() => toDayjs(timeline.value.start))
  const chartEndDayjs   = computed(() => toDayjs(timeline.value.end))

  const totalNumOfMinutes = computed(() => {
    return chartEndDayjs.value.diff(chartStartDayjs.value, 'minutes')
  })

  const mapTimeToPosition = (time) => {
    const width = chartSizeWidth.value || 0
    const diffFromStart = toDayjs(time).diff(chartStartDayjs.value, 'minutes', true)
    return Math.ceil((diffFromStart / totalNumOfMinutes.value) * width)
  }

  const mapPositionToTime = (xPos) => {
    const width = chartSizeWidth.value || 0
    const diffFromStart = (xPos / width) * totalNumOfMinutes.value
    return format(chartStartDayjs.value.add(diffFromStart, 'minutes'), DATE_FORMAT)
  }

  // Prolet: calculate snap

  const mapPixelsToMins = (pixels) => {
    const width = chartSizeWidth.value || 0
    return totalNumOfMinutes.value * pixels / width
  }

  const mapMinsToPixels = (minutes) => {
    const width = chartSizeWidth.value || 0
    return width / totalNumOfMinutes.value * minutes
  }

  const snapPosition = (xPos, snapFactor) => {
    if (snapFactor > 0) {
      xPos = Math.floor((xPos / snapFactor) + 0.5) * snapFactor
    }
    return xPos
  }

  return {
    mapTimeToPosition,
    mapPositionToTime,
    mapMinsToPixels,
    mapPixelsToMins,
    snapPosition
  }
}